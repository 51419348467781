import { NextLink } from "components/next-link";
import { Box } from "components/ui/box";
import { Button } from "components/ui/button";
import { Container } from "components/ui/container";
import { Typography } from "components/ui/Typography";
import React from "react";
import { mainPageWidth } from "src/constants";

export const NotFound = ({
  inMainPage,
  backLink,
  title,
  description,
  backToButtonText,
}: {
  title?: string;
  description?: string;
  backToButtonText?: string;
  backLink?: string;
  inMainPage: boolean;
}) => {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: inMainPage ? mainPageWidth : "100%",
      }}
    >
      <Box sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          {title || "Sorry, page not found!"}
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          {description ||
            "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling."}
        </Typography>

        <Box
          component="img"
          src="/illustrations/illustration_404.svg"
          sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
        />

        <Button
          href={backLink || "/"}
          size="large"
          variant="contained"
          component={NextLink}
        >
          {backToButtonText || "Go to Home"}
        </Button>
      </Box>
    </Container>
  );
};
