import { NotFound } from "components/error/not-found";
import { NextLink } from "components/next-link";
import { Box } from "components/ui/box";
import React from "react";

export default function Custom404() {
  return (
    <Box
      sx={{
        height: "100vh",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "24px",
          left: "24px",
        }}
        component={NextLink}
        href="/"
      ></Box>
      <NotFound inMainPage={false} />
    </Box>
  );
}
